import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface fontFamilyState {
  fontFamily: string;
}

const initialState = {
  fontFamily: 'serif',
} as fontFamilyState;

const fontFamilySlice = createSlice({
  name: "styles",
  initialState,
  reducers: {
    setFontFamily(state, action: PayloadAction<string>) {
      state.fontFamily = action.payload;
    },
  },
});

export const { setFontFamily } = fontFamilySlice.actions;

export default fontFamilySlice.reducer;