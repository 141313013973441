import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Checkout,
  Customer,
  CustomerAccessToken,
  Product,
} from "../services/shopify/types";

interface ShopifyState {
  customer: Customer | null;
  checkout: Checkout | null;
  productsByProductNumberId: {
    [productId: string]: Product;
  };
  productsById: {
    [productId: string]: Product;
  };
  accessToken: CustomerAccessToken;
}

const initialState = {
  customer: null,
  checkout: null,
  productsById: {},
  productsByProductNumberId: {},
} as ShopifyState;

const shopifySlice = createSlice({
  name: "shopify",
  initialState,
  reducers: {
    setCheckout(state, action: PayloadAction<Checkout | null>) {
      state.checkout = action.payload;
    },
    setCustomer(state, action: PayloadAction<Customer | null>) {
      state.customer = action.payload;
    },
    addProduct(
      state,
      action: PayloadAction<{ product: Product; productNumberId?: string }>
    ) {
      const { product, productNumberId } = action.payload;
      if (!product || !product.id) {
        return;
      }
      state.productsById[product.id] = product;
      if (productNumberId) {
        state.productsByProductNumberId[productNumberId] = product;
      }
    },
    removeProduct(
      state,
      action: PayloadAction<{ productId: string; productNumberId?: string }>
    ) {
      const { productId, productNumberId } = action.payload;
      delete state.productsById[productId];
      if (productNumberId) {
        delete state.productsByProductNumberId[productNumberId];
      }
    },
  },
});

export const { setCheckout, setCustomer, addProduct, removeProduct } =
  shopifySlice.actions;

export default shopifySlice.reducer;
