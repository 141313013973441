// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-our-ingredients-tsx": () => import("./../../../src/pages/about/our-ingredients.tsx" /* webpackChunkName: "component---src-pages-about-our-ingredients-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-food-service-tsx": () => import("./../../../src/pages/contact/food-service.tsx" /* webpackChunkName: "component---src-pages-contact-food-service-tsx" */),
  "component---src-pages-contact-international-sales-tsx": () => import("./../../../src/pages/contact/international-sales.tsx" /* webpackChunkName: "component---src-pages-contact-international-sales-tsx" */),
  "component---src-pages-contact-product-inquiries-tsx": () => import("./../../../src/pages/contact/product-inquiries.tsx" /* webpackChunkName: "component---src-pages-contact-product-inquiries-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-food-service-tsx": () => import("./../../../src/pages/food-service.tsx" /* webpackChunkName: "component---src-pages-food-service-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsroom-fact-sheets-tsx": () => import("./../../../src/pages/newsroom/fact-sheets.tsx" /* webpackChunkName: "component---src-pages-newsroom-fact-sheets-tsx" */),
  "component---src-pages-newsroom-images-tsx": () => import("./../../../src/pages/newsroom/images.tsx" /* webpackChunkName: "component---src-pages-newsroom-images-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-newsroom-videos-tsx": () => import("./../../../src/pages/newsroom/videos.tsx" /* webpackChunkName: "component---src-pages-newsroom-videos-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-recipes-search-tsx": () => import("./../../../src/pages/recipes-search.tsx" /* webpackChunkName: "component---src-pages-recipes-search-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-dynamic-page-tsx": () => import("./../../../src/templates/dynamic-page.tsx" /* webpackChunkName: "component---src-templates-dynamic-page-tsx" */),
  "component---src-templates-food-service-tsx": () => import("./../../../src/templates/food-service.tsx" /* webpackChunkName: "component---src-templates-food-service-tsx" */),
  "component---src-templates-pack-tsx": () => import("./../../../src/templates/pack.tsx" /* webpackChunkName: "component---src-templates-pack-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */)
}

