import React, { useLayoutEffect } from "react";
import { useController } from "react-scroll-parallax";

export const ParallaxCache = () => {
  const { parallaxController } = useController();

  useLayoutEffect(() => {
    if (parallaxController) {
      const handler = () => parallaxController.update();
      window.addEventListener("load", handler);
      return () => window.removeEventListener("load", handler);
    }
  }, [parallaxController]);

  return null;
};
