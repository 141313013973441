import { isMobile } from "react-device-detect";

export enum ParallaxTransformations {
  SCALE = "SCALE",
  TRANSLATE_X = "TRANSLATE_X",
  TRANSLATE_Y = "TRANSLATE_Y",
  ROTATE = "ROTATE",
}
export type ParallaxAnimation = {
  transformation: ParallaxTransformations;
  from: number;
  to: number;
  unit: string | undefined;
};

export type ParallaxElement = {
  element: HTMLElement;
  animations: Array<ParallaxAnimation>;
};

export type ParallaxRootElement = {
  root: HTMLElement;
  rect: DOMRect;
  offset?: number;
  elements: {
    [key: string]: ParallaxElement;
  };
};

export const getLimits = (
  mobile: string[] | number[] = ["-20px", "0px"],
  desktop: string[] | number[] = ["-200px", "0px"]
) => {
  return isMobile ? mobile : desktop;
};

export const getParallaxValue = (ratio: number, min: number, max: number) => {
  return ratio * (max - min) + min;
};

export const getCSSAnimation = (
  transformation: ParallaxTransformations,
  value: number | string
) => {
  if (transformation === ParallaxTransformations.TRANSLATE_X) {
    return `translateX(${value})`;
  }
  if (transformation === ParallaxTransformations.TRANSLATE_Y) {
    return `translateY(${value})`;
  }
  if (transformation === ParallaxTransformations.ROTATE) {
    return `rotate(${value}deg)`;
  }
  return `scale(${value})`;
};

export const applyAnimation = (
  rootElement: ParallaxRootElement,
  ratio: number
) => {
  try {
    Object.values(rootElement?.elements).forEach((e) => {
      const animations = e?.animations?.map((animation) => {
        const value = getParallaxValue(ratio, animation?.from, animation?.to);
        return getCSSAnimation(
          animation?.transformation,
          `${value}${animation?.unit || ""}`
        );
      });
      e.element.style.transform = animations.join(" ");
    });
  } catch (e) {
    console.log(e);
  }
};

export const getThreshold = (interval: number) => {
  const threshold = [];
  let i = 0;
  while (i < 1) {
    threshold.push(i);
    i += interval;
  }
  return threshold;
};

export const getRatio = (entry: IntersectionObserverEntry) => {
  const { boundingClientRect } = entry;
  if (boundingClientRect?.top >= 0) {
    return 1 - 1;
  }
  if (boundingClientRect?.top + boundingClientRect?.height < 0) {
    return 1 - 0;
  }
  return (
    1 -
    (boundingClientRect?.top + boundingClientRect?.height) /
      boundingClientRect?.height
  );
};

export const getRatioBasedOnScroll = (
  rect: DOMRect,
  scroll: number,
  offset = 0
) => {
  const { top, height } = rect;

  if (scroll + offset < top) {
    return 0;
  }
  if (scroll + offset > top + height) {
    return 1;
  }
  return (scroll + offset - top) / height;
};
