import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SignUpModal = {
  show: boolean;
  timesDisplayed: number;
};

type LanguagePickerModal = {
  show: boolean;
};

interface ModalsState {
  signUp: SignUpModal;
  languagePicker: LanguagePickerModal;
}

const initialState = {
  signUp: {
    show: false,
    timesDisplayed: 0,
  },
  languagePicker: {
    show: false,
  },
} as ModalsState;

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    updateSignUpModal(state, action: PayloadAction<SignUpModal>) {
      state.signUp = {
        ...(action.payload || {}),
      };
    },
    openSignUpModal(state) {
      state.signUp.show = true;
      state.signUp.timesDisplayed += 1;
    },
    closeSignUpModal(state) {
      state.signUp.show = false;
    },
    openLanguagePickerModal(state) {
      state.languagePicker.show = true;
    },
    closeLanguagePickerModal(state) {
      state.languagePicker.show = false;
    },
  },
});

export const {
  updateSignUpModal,
  openSignUpModal,
  closeSignUpModal,
  openLanguagePickerModal,
  closeLanguagePickerModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;
