import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  id: string | null;
  name: string | null;
}

const initialState = {
  id: null,
  name: "",
} as UserState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
  },
});

export const { setName, setId } = userSlice.actions;

export default userSlice.reducer;
