import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { applyAnimation, getRatioBasedOnScroll } from "../../helpers/parallax";

const clientSideWindow =
  typeof window !== "undefined"
    ? window
    : {
        innerWidth: 0,
        addEventListener: () => 0,
        removeEventListener: () => 0,
      };

const testForPassiveScroll = () => {
  let supportsPassiveOption = false;
  try {
    const opts = Object.defineProperty({}, "passive", {
      get() {
        supportsPassiveOption = true;
      },
    });
    clientSideWindow.addEventListener("test", null, opts);
    clientSideWindow.removeEventListener("test", null, opts);
  } catch (e) {
    console.log(e);
  }
  return supportsPassiveOption;
};

const CustomParallax = () => {
  const supportsPassive = useRef(testForPassiveScroll());
  const ticking = useRef(false);
  const parallaxElements = useAppSelector(
    (state) => state.parallax.parallaxElements
  );

  const parallaxElementsRef = useRef(parallaxElements);

  const addListeners = () => {
    document.addEventListener(
      "scroll",
      handleScroll,
      supportsPassive.current ? { passive: true } : false
    );
  };

  function removeListeners() {
    document.addEventListener(
      "scroll",
      handleScroll,
      supportsPassive.current ? { passive: true } : false
    );
  }

  const animateElements = () => {
    try {
      const scrollPosition = clientSideWindow.scrollY;
      Object.values(parallaxElementsRef?.current).forEach((pRootElement) => {
        const ratio = getRatioBasedOnScroll(
          pRootElement.rect,
          scrollPosition,
          pRootElement?.offset
        );
        applyAnimation(pRootElement, ratio);
      });
      ticking.current = false;
    } catch (e) {
      console.log(e);
    }
  };

  const handleScroll = () => {
    if (!ticking.current) {
      ticking.current = true;
      clientSideWindow.requestAnimationFrame(animateElements);
    }
  };

  useEffect(() => {
    parallaxElementsRef.current = parallaxElements;
    animateElements();
    addListeners();
    return () => {
      removeListeners();
    };
  }, [parallaxElements]);
  return null;
};

export default CustomParallax;
