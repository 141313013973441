import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ParallaxProvider } from "react-scroll-parallax";
import store, { persistor } from "./src/store";
import { CustomParallax } from "./src/components/CustomParallax";
import { ParallaxCache } from "./src/components/ParallaxCache";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  if (typeof window === "undefined") {
    return (
      <ParallaxProvider>
        <ParallaxCache />
        <Provider store={store}>
          <CustomParallax />
          {element}
        </Provider>
      </ParallaxProvider>
    );
  }
  return (
    <ParallaxProvider>
      <ParallaxCache />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CustomParallax />
          {element}
        </PersistGate>
      </Provider>
    </ParallaxProvider>
  );
};
