import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParallaxElement, ParallaxRootElement } from "../helpers/parallax";

interface ParallaxState {
  parallaxElements: {
    [key: string]: ParallaxRootElement;
  };
}

const initialState = {
  parallaxElements: {},
} as ParallaxState;

const parallaxSlice = createSlice({
  name: "parallax",
  initialState,
  reducers: {
    addParallaxRootElement(
      state,
      action: PayloadAction<{ key: string; element: ParallaxRootElement }>
    ) {
      try {
        const {
          payload: { key, element },
        } = action;
        state.parallaxElements[key] = element;
      } catch (e) {
        console.log(e);
      }
    },
    removeParallaxRootElement(state, action: PayloadAction<string>) {
      try {
        delete state.parallaxElements[action?.payload];
      } catch (e) {
        console.log(e);
      }
    },
    addParallaxElement(
      state,
      action: PayloadAction<{
        rootKey: string;
        key: string;
        element: ParallaxElement;
      }>
    ) {
      try {
        const {
          payload: { rootKey, key, element },
        } = action;
        state.parallaxElements[rootKey].elements[key] = element;
      } catch (e) {
        console.log(e);
      }
    },
    removeParallaxElement(
      state,
      action: PayloadAction<{
        rootKey: string;
        key: string;
      }>
    ) {
      try {
        const {
          payload: { rootKey, key },
        } = action;
        delete state.parallaxElements[rootKey].elements[key];
      } catch (e) {
        console.log(e);
      }
    },
  },
});

export const {
  addParallaxRootElement,
  removeParallaxRootElement,
  addParallaxElement,
  removeParallaxElement,
} = parallaxSlice.actions;

export default parallaxSlice.reducer;
