require("intersection-observer");
import "./src/styles/global.css";
import "./src/styles/fonts.css";
import wrapWithProvider from "./wrap-with-provider";

export const wrapRootElement = wrapWithProvider;

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  if (prevRouterProps?.location?.pathname !== routerProps?.location?.pathname) {
    window.scrollTo(0, 0);
  }
  return false;
};
