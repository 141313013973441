import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LocaleState {
  locale: string | null;
}

const initialState = {
  locale: process.env.GATSBY_ACTIVE_LOCALE,
} as LocaleState;

const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload;
    },
  },
});

export const { setLocale } = localeSlice.actions;

export default localeSlice.reducer;
