import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type KnownLabels = {
  cart: string | null;
  account: string | null;
  search: string | null;
  variant: string | null;
};

interface LabelsState {
  [language: string]: KnownLabels & { [label: string]: string };
}

const initialState = {
  en: {
    account: "My Account",
    cart: "Cart",
    search: "Search",
    variant: "Variant",
    minus: "Minus",
    plus: "Plus",
    open: "Open",
    close: "Close",
    download: "Download",
    next: "Next",
    previous: "Previous",
    play: "Play",
    pause: "Pause",
    locator: "Locator",
    emptyImage: "Empty Image",
    article: "article",
    tags: "tags",
    requiredField: "This field is required",
    validPhone: "Must be a valid phone no.",
    validEmail: "Must be a valid email",
  },
} as LabelsState;

const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    setLabels(
      state,
      action: PayloadAction<{
        labels: { [key: string]: string };
        language: string;
      }>
    ) {
      const { labels, language } = action.payload;
      if (!language || !labels) {
        return;
      }
      try {
        if (!state[language]) {
          state[language] = {};
        }
        Object.entries(labels).forEach(([key, value]) => {
          state[language][key] = value;
        });
      } catch (e) {
        console.log(e);
      }
    },
    setLabel(
      state,
      action: PayloadAction<{ key: string; value: string; language: string }>
    ) {
      const { key, value, language } = action.payload;
      if (!language || !key || !value) {
        return;
      }
      if (!state[language]) {
        state[language] = {};
      }
      state[language][key] = value;
    },
  },
});

export const { setLabels, setLabel } = labelsSlice.actions;

export default labelsSlice.reducer;
